import Lgct from '_/images/ct.svg';
import Banner from '@/components/Layout/Banner';
import GlobalContainer from '@/components/Layout/GlobalContainer';
import ErrorPage from '@/components/Layout/ErrorPage';
import { useEffect } from 'react';
import { SendEventGTMWithSingleString } from '@/lib/gtm';

export default function Custom404() {
  useEffect(() => {
    SendEventGTMWithSingleString('page not found');
  }, []);

  return (
    <GlobalContainer whiteBg center>
      <Banner />
      <div className="[&_svg]:h-full [&_svg]:w-[60vw] phone:[&_svg]:max-h-[100px]">
        <Lgct />
      </div>
      <ErrorPage statusCode="404" />
    </GlobalContainer>
  );
}
